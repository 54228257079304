import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "../../../core/services/global-services/global.service";
import { Subject, Subscription } from "rxjs";
import { ApiService } from "../../../core/services/api-services/api.service";
import { LoadingScreenService } from "../../../core/services/loading-screen/loading-screen.service";
import { MatDialog } from "@angular/material/dialog";
import { ExecutePaymentService } from "../../../core/services/execute-payment/execute-payment.service";
import { PaymentIframeService } from "src/app/shared/components/iframe-payment/payment-iframe.services";

@Component({
  selector: "app-payment-amount",
  templateUrl: "./payment-amount.component.html",
  styleUrls: ["./payment-amount.component.sass"],
})
export class PaymentAmountComponent implements OnInit {
  public amountToPay: number;
  public lastPayment;
  public banSelected;
  public billDate;
  public billDueDate;
  public subscriberEmail;
  public subscriberLastName;
  public subscriberName;
  public confirmNumber;
  public token: string = sessionStorage.getItem("token");
  paymentReceived: Subscription;
  public screenActual = 1;
  public activateLine = false;
  // isDisabled = false;
  allowChangeValue = true;
  public firstNameIP = "";
  public lastNameIP = "";
  public emailIP = "";
  public amountIP = "";
  public selectBanIP = "";
  public currentSuscriberIP = "";
  public locationIdIP = "";
  public invoiceNumberIP = "";
  public installmentCountIP = 0;
  public authorizationNumber = "";
  public referenceNumber = "";
  public displayConfirmation: boolean = false;
  public loadIframe: Boolean = false;
  updatePayment = new Subject();
  private endOfPayment: boolean = false;
  private endOfProcesses: boolean = false;
  private resulPayment: any;
  public paymentStartTime: number = 0;
  showPaymentAmounts = true;
  billBalance;
  showOtherAmount = false;
  canChangeAmount = true;
  optionSelected;
  selectedAmount: any;
  pastDueAmount;
  canUseAccountAmounts = true;
  optionTitle1;
  optionTitle2;
  optionTitle3;
  showMinum = true;
  dataSent = false;
  // si el monto viene con cr no hay minimo.
  // no pagar menos del minimo.
  constructor(
    private loadingScreenService: LoadingScreenService,
    private services: ApiService,
    public global: GlobalService,
    private router: Router,
    public dialog: MatDialog,
    public executePaymentService: ExecutePaymentService
  ) {
    this.getTooltips();
  }

  ngOnInit() {
    this.athProcess();
    sessionStorage.setItem("ath-route", "payment");
    let banStatus = JSON.parse(atob(sessionStorage.getItem("getSubscriber")))
      .accountInfo.BANStatus;

    let isfromAdmin = JSON.parse(atob(sessionStorage.getItem("getSubscriber")))
      .subscriberInfo.servSubscriber;

    if (banStatus && banStatus.toLowerCase() == "s") {
      this.canChangeAmount = false;
    }

    this.billBalance = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.billBalance;

    if (!this.billBalance) {
      this.billBalance = JSON.parse(
        atob(sessionStorage.getItem("getSubscriber"))
      ).accountInfo.pastDueAmount;
    }
    if (this.billBalance.toString().includes("CR")) {
      this.canUseAccountAmounts = false;
      //this.showMinum = false;
    }
    this.global.setStep(2);

    this.paymentReceived = this.global.paymentReceived.subscribe(
      (value: boolean) => {
        if (value === true) {
          this.loadingScreenService.stopLoading();
          this.global.notif("Se ha efectuado el pago de la factura.");
          this.screenActual = 2;
        }
      }
    );
    this.updatePayment.subscribe((data: any) => {
      if (this.endOfProcesses == true && this.endOfPayment == true) {
        if (this.resulPayment.success == true) {
        } else {
          this.endOfPayment = false;
          this.paymentStartTime = 0;
        }
      }
    });
    this.global.setPaymentMethod();
    try {
      let objSubscriber = JSON.parse(
        atob(sessionStorage.getItem("getSubscriber"))
      );
      this.firstNameIP = objSubscriber.accountInfo.firstName;
      this.lastNameIP = objSubscriber.accountInfo.lastName;
      if (objSubscriber.accountInfo.email != undefined) {
        this.emailIP = objSubscriber.accountInfo.email;
      } else {
        this.emailIP = this.global.emailIframePay;
      }
      this.selectBanIP = objSubscriber.accountInfo.BAN;
      this.currentSuscriberIP = objSubscriber.accountInfo.subscriberNumber;
    } catch (error) {}
    this.pastDueAmount = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.pastDueAmount;
    // this.amountToPay = this.pastDueAmount
    console.log(this.pastDueAmount);
    this.lastPayment = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.lastPaymentAmount;
    this.banSelected = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.BAN;
    this.billDate = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.billDate;
    this.billDueDate = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.billDueDate;
    this.subscriberEmail = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.email;
    this.subscriberName = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.firstName;
    this.subscriberLastName = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.lastName;
    this.global.amountToPay2 = JSON.parse(
      atob(sessionStorage.getItem("getSubscriber"))
    ).accountInfo.pastDueAmount;
    if (sessionStorage.getItem("paymentsLink")) {
      this.allowChangeValue = JSON.parse(
        atob(sessionStorage.getItem("paymentsLink"))
      ).allowChangeValue;
    }
  }

  backTo() {
    this.global.loadFooter = false;
    this.router.navigate(["postpaid_select_option"]);
  }
  errorMessage(e: any) {
    this.loadingScreenService.stopLoading();
  }
  outLoadIframe(event: any) {
    this.loadIframe = true;
  }
  // browser() {
  // var es_chrome = navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
  // var es_firefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  // var es_opera = navigator.userAgent.toLowerCase().indexOf("es_opera") > -1;
  // var es_ie = navigator.userAgent.toLowerCase().indexOf("MSIE") > -1;
  // if (es_chrome) {
  // }
  // }

  validateMinimun() {
    if (this.pastDueAmount) {
      if (this.billBalance.toString().includes("CR")) {
        return "0.00";
      } else {
        return Number(this.pastDueAmount) > 0 ? this.pastDueAmount : "0.00";
      }
    } else {
      return "0.00";
    }
  }

  fixAmount(amount: any) {
    if (amount.toString().includes("CR")) {
      return amount.toString();
    } else {
      const newAmount = amount.toString().replace(",","")
      return Number(newAmount).toFixed(2).toString();
      //return Number(amonut).toFixed(2).toString();
    }
  }

  goTo() {
    this.paymentStartTime = this.global.paymentStartTime;
    // const typeMessage = "payment";
    if (this.amountToPay) {
      if (this.amountToPay <= 800) {
        if (this.amountToPay >= 5) {
          this.loadingScreenService.startLoading();
          sessionStorage.setItem("amountToPay", this.amountToPay.toString());
          this.amountIP = this.amountToPay.toString();

          this.global.setStep(3);
          this.global.currentScreen = 2;
        } else {
          this.global.notif("El monto no puede ser menor a $5.00.");
        }
      } else {
        this.global.notif("El monto no puede ser mayor a $800.00.");
      }
    } else {
      this.global.notif("Indique el monto a pagar");
    }
  }
  backToOptions() {
    this.global.setStep(1);
    this.global.currentScreen = 1;
    this.router.navigate(["postpaid_select_option"]);
  }
  ngOnDestroy() {
    this.paymentReceived.unsubscribe();
  }
  updateScreen(event: number) {
    this.screenActual = event;
  }

  selectAmount(index: number) {
    this.showOtherAmount = false;
    if (!this.canUseAccountAmounts && index !== 3) {
      console.log(this.canUseAccountAmounts, this.optionSelected);
      return;
    } else {
      if (index === 1) {
        this.amountToPay = this.billBalance;
        this.optionSelected = index;
      } else if (index === 2) {
        this.amountToPay = JSON.parse(
          atob(sessionStorage.getItem("getSubscriber"))
        ).accountInfo.pastDueAmount;
        this.optionSelected = index;
      } else {
        this.showOtherAmount = true;
        this.optionSelected = index;
      }
    }
  }

  getTooltips() {
    this.services.getLocalData("tooltip.json").subscribe((resp: any) => {
      console.log(resp);
      this.optionTitle1 = resp.title1;
      this.optionTitle2 = resp.title2;
      this.optionTitle3 = resp.title3;
    });
  }

  athProcess() {
    if (JSON.parse(sessionStorage.getItem("athData")!!)) {
      this.amountToPay = Number(sessionStorage.getItem("amountToPay"));
      let dataAth = JSON.parse(sessionStorage.getItem("athData")!!);
      if (dataAth.cancelled) {
        this.global.notif(
          "Has cancelado el proceso de pago por favor intenta nuevamente."
        );
        sessionStorage.removeItem("athData");
      } else {
        setTimeout(() => {
          this.loadingScreenService.startLoading();
        }, 1000);
        this.getTransactionStatus(btoa(sessionStorage.getItem("athData")!!));
      }
    }
  }

  getTransactionStatus(data: any, typeResult = "") {
    this.services.getTransactionStatus(data).subscribe(
      (resp) => {
        this.decryptTokenByCrypto(resp.dataToken);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  decryptTokenByCrypto(data: any) {
    this.services.decryptTokenByCrypto(data).subscribe(
      (resp) => {
        console.log(resp);
        let dataResult = JSON.parse(
          atob(resp.iframePaymentResult.paymentResult)
        );
        console.log(dataResult);
        // this.resultPaymentIframe(dataResult);
        this.global.resultPaymentIframe(dataResult); // ojo
        sessionStorage.removeItem("athData");
      },
      (error) => {
        console.log(error);
      }
    );
  }

  updateResult(event) {
    if (!this.dataSent) {
      this.global.resultPaymentIframe(event);
      this.dataSent = true;
      setTimeout(() => {
        this.dataSent = false;
      }, 1000);
    }
  }
}
